import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import Select from 'react-select';

const categories = [
  {
    label: 'Amazon.sa > Amazon UAE',
    options: [
      { label: 'Arts & Crafts', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16636732031&dc&language=en&ds=v1%3AJyhDbfxgE2oqHO9AiKzvpurrML1%2BDMZiCEpVdkvLJ2w&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_1' },
      { label: 'Automotive', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12462877031&dc&language=en&ds=v1%3A2VarRQBYiBwdZPduKBrNZaiujSRSuiKBsGAUbDxBBNI&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_2' },
      { label: 'Baby Products', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12462934031&dc&language=en&ds=v1%3AmsCTd%2FmfoU14uo484EVX57qUa%2BAkDa8m%2BNAlTjktuzQ&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_3' },
      { label: 'Beauty', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12462991031&dc&language=en&ds=v1%3AL1DVIvwv%2F9Gi5wJNUoeQNTxjooAE9D1lwgY5SpBsYkw&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_4' },
      { label: 'Books', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463048031&dc&language=en&ds=v1%3AmfjzXpE5%2FWbGN1tw%2BtnBFos4C%2BnFV%2F2dxTexm0X%2B8uc&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_5' },
      { label: 'DIY & Tools', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463390031&dc&language=en&ds=v1%3A%2FnAK9JUSsc%2Ft0H%2BQli%2BhXZkV1VyjMBteiHqMfAa20h4&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_6' },
      { label: 'Electronics', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463162031&dc&language=en&ds=v1%3AOwjbcfrjqcKiXQS85GVX6Vzj1B3BPZIkW%2B6x7hQjhcQ&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_7' },
      { label: 'Everything Else', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463903031&dc&language=en&ds=v1%3AmOLebAysrb9Bio9levIJFXiAgcAJWgn3KZ7wCJWkXYI&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_8' },
      { label: 'Fashion', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463219031&dc&language=en&ds=v1%3A0m3QJfOuC9ycrkMx1T2zja2556oUmmMfU%2BDvOxHuC4E&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_9' },
      { label: 'Gift Cards', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16636846031&dc&language=en&ds=v1%3Al3nZsxPhnu8mG2F%2F%2Bh7NVDFr3dXKDWqfO6gXBOj%2BFYc&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_10' },
      { label: 'Grocery', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16636903031&dc&language=en&ds=v1%3A6D3X16adA%2BleYUyrtl4NhC6tFEhUFGzkdeLIdL31Pao&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_11' },
      { label: 'Health', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463276031&dc&language=en&ds=v1%3AknTv05Rb1FB3lTHTypSd3HpxtpBcWK6iUK1%2Bs00AmVo&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_12' },
      { label: 'Home', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463333031&dc&language=en&ds=v1%3AyHDoTuYZ54IJbddCxS3ReJ6s0pdJjwhKx8NzSr%2Bbzvg&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_13' },
      { label: 'Industrial & Scientific', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16636960031&dc&language=en&ds=v1%3Acpr%2BR5C48kSeTwlhfWdD4rAHwb5vJez%2FgD5U4d9DZPE&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_14' },
      { label: 'Musical Instruments', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16637188031&dc&language=en&ds=v1%3AIm0%2FnXx0%2BbNnUBnJxQNVYgABRjmeMvju2QHe1rZF9nE&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_15' },
      { label: 'Office Products', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16637245031&dc&language=en&ds=v1%3AVoxp7o5eVZt6tcvvKvGfaUzZxh0136bI3zewARQjEg8&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_16' },
      { label: 'Patio, Lawn & Garden', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16636789031&dc&language=en&ds=v1%3A3v6078KfdrM6xgARD7EWTNYivwSJq9W%2BaoSEVj4RNZk&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_17' },
      { label: 'Pet Supplies', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16637302031&dc&language=en&ds=v1%3AC7Y%2FhWansFUCo8%2Bt5zwnXUFtDwgfvqV9OeLyMi4o8iQ&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_18' },
      { label: 'Software', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A16637359031&dc&language=en&ds=v1%3A0z2LpKKhq7pxclgpxOYHI8oRQI5AmXnWGnLT7m4elS4&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_19' },
      { label: 'Sporting Goods', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463561031&dc&language=en&ds=v1%3AQYPyVmsRlzgFcVy%2FHmxN0yYdkrfbvBAAb%2BbWsLRFp08&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_20' },
      { label: 'Toys', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463618031&dc&language=en&ds=v1%3AvBW3%2FFdk%2BMiYZFUy4gs5tMyqXvlpe9IxtqLGskFnkfw&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_21' },
      { label: 'Videogames', value: 'https://www.amazon.sa/s?i=merchant-items&me=A33KVCWMBJ7XV6&rh=n%3A12463675031&dc&language=en&ds=v1%3A%2F3jiTyRf%2BF8Vu0MOrbgoC98U5xWmq4iHdpGL9kknyts&marketplaceID=A17E79C6D8DWNP&qid=1697684112&rnid=17120842031&ref=sr_nr_n_22' }
    ]
  },
  {
    label: 'Amazon.sa > Amazon UK',
    options: [
      { label: 'Arts & Crafts', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16636732031&dc&language=en&ds=v1%3At5X0uHVFQQIu%2Bnm1KpbfWPeHAMTazPCfx4Jsc3l8pzw&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_2' },
      { label: 'Automotive', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12462877031&dc&language=en&ds=v1%3AOXAiZz5wEjfyxcF4sVNkYFqAGGI1bNQuRhJf2QaFqak&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_3' },
      { label: 'Baby Products', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12462934031&dc&language=en&ds=v1%3A1f3t8S%2FxHD%2Bd7qoEf2HOY03aTwar57oELK4qfTge%2F5g&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_4' },
      { label: 'Beauty', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12462991031&dc&language=en&ds=v1%3AncmIGc5kz4KvqQTpX60epuY6ozWFqTyAsioxIvE4Jn4&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_5' },
      { label: 'Books', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463048031&dc&language=en&ds=v1%3AfQcKCpHN5TYTvzaGnkB6tX8alpKl8Vnowdrx%2Fi6q0DA&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_6' },
      { label: 'DIY & Tools', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463390031&dc&language=en&ds=v1%3AruQ%2FWQnawD1tbK6lZ96MeQituY79RRQc5x%2BPyAa1bYk&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_7' },
      { label: 'Electronics', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463162031&dc&language=en&ds=v1%3AE4AykbHHrQmIp6Sj9CMgO8x%2F0VpzHHeOfqFKtcLEVPI&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_8' },
      { label: 'Everything Else', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463903031&dc&language=en&ds=v1%3AyT7TQG21Wa%2FaQkchYNtm%2FhC4N4aOJRLWtiXqH0xOg9k&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_9' },
      { label: 'Fashion', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463219031&dc&language=en&ds=v1%3AuIxZNuMYc4jOH57bnI%2B%2FJO3ZC5Gmqwh%2Fg6OMMIqi4UQ&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_10' },
      { label: 'Grocery', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16636903031&dc&language=en&ds=v1%3AVcevMdAHUSv1hMvwfadlBgfkdbQdX8c4o5VdRCCBLck&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_11' },
      { label: 'Health', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463276031&dc&language=en&ds=v1%3A2wUL53pXLzdvZplqVt8JoUU28NuVxDrPkcpq4vqa5Ag&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_12' },
      { label: 'Home', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463333031&dc&language=en&ds=v1%3A%2Fs9Cdraxbfbeg0ifWj%2FrHmLf%2BWz7LMwQYgbebD0YTKM&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_13' },
      { label: 'Industrial & Scientific', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16636960031&dc&language=en&ds=v1%3AJNMQYPizP5rBhrgIPljKWl98K5OJ7jKekghsp1zYYfY&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_14' },
      { label: 'Musical Instruments', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16637188031&dc&language=en&ds=v1%3APaysQ4in%2BZhjkxoRBDNEMLdPzqeUd1RzxP9bZitRo64&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_15' },
      { label: 'Office Products', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16637245031&dc&language=en&ds=v1%3AfgsFJeVUhrww2fpmXR2t3EpqZYCz3YgSwDpzuqGrECw&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_16' },
      { label: 'Patio, Lawn & Garden', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16636789031&dc&language=en&ds=v1%3AguCvDq6eFXn%2Fp9AsRm50g0HGjE54f9WFjHaBPgwwgVE&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_17' },
      { label: 'Pet Supplies', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16637302031&dc&language=en&ds=v1%3AR4RTPVfVESE%2BBFN92cvtUUAJ8avPpjIqA%2BSL72UP36M&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_18' },
      { label: 'Software', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A16637359031&dc&language=en&ds=v1%3As1aCBJsjE733se49139ETpdVHy4N3fqQXtENqT8itYY&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_19' },
      { label: 'Sporting Goods', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463561031&dc&language=en&ds=v1%3AlXuHWhc1pNzvoUy4aNnap%2FQcmwycOLH3aNNO7O8ayYc&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_20' },
      { label: 'Toys', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463618031&dc&language=en&ds=v1%3AJMYu07kx0X8w9xF2L0zPfxLO48fO8K%2FEn7dqux%2FTy2g&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_21' },
      { label: 'Videogames', value: 'https://www.amazon.sa/s?i=merchant-items&me=A2R3VIMQ1WRL53&rh=n%3A12463675031&dc&language=en&ds=v1%3A2tA3127hF0qSoSSr%2Ftblulj2mVJBMK2et%2BYwwznn490&marketplaceID=A17E79C6D8DWNP&qid=1697683810&rnid=17120842031&ref=sr_nr_n_22' }
    ]
  },
  {
    label: 'Amazon.sa > Amazon US',
    options: [
      { label: 'Arts & Crafts', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16636732031&dc&language=en&ds=v1%3AQtvYN0MQZK%2FGetFCnTyidStw1y0O87qJdSYkEfNPVa8&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_1' },
      { label: 'Automotive', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12462877031&dc&language=en&ds=v1%3ANcmiOreVjf4tt1JuozEOD4eZN1ZMUV88QxyVCTXFPLM&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_2' },
      { label: 'Baby Products', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12462934031&dc&language=en&ds=v1%3AxHgivuuv9CFxV69W0hbBIbuE5rJ9qUqKMiRikFGPlsk&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_3' },
      { label: 'Beauty', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12462991031&dc&language=en&ds=v1%3A7q%2B81bCqCVbwHJtJBxlM9hcw%2BH5YVmGfhaB%2BENJcnrA&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_4' },
      { label: 'Books', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463048031&dc&language=en&ds=v1%3AebbZynCrfwH%2FwVpKmg%2BTChhjPIseOej%2FxR7TaixdawQ&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_5' },
      { label: 'DIY & Tools', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463390031&dc&language=en&ds=v1%3AY4W5Wbd6tLcV%2BzoyFfzk9jAeRXAkKqUUKIZsfqNSl2w&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_6' },
      { label: 'Electronics', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463162031&dc&language=en&ds=v1%3AtB1p5cO4fIENaZlPtTs7XEholL1Tz8JmviYmhyQkKzg&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_7' },
      { label: 'Everything Else', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463903031&dc&language=en&ds=v1%3AHyQ16xEBgv3kZtruk9kwh2RHeXIYddhJkvHwVEqbCiA&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_8' },
      { label: 'Fashion', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463219031&dc&language=en&ds=v1%3Akn9q%2B1FmyIejdyPaSNHBvc6NUVLavaqUfvRS3F6UrBo&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_9' },
      { label: 'Gift Cards', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16636846031&dc&language=en&ds=v1%3AKtJpake9cU8QOwDp6kXDS11dFYLafSs4DbP2XBl5bi4&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_10' },
      { label: 'Grocery', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16636903031&dc&language=en&ds=v1%3AiWLJGVxtHQQOFTaKAlc7lpGn94aP%2Blo1ibGjLubGIjc&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_11' },
      { label: 'Health', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463276031&dc&language=en&ds=v1%3APT%2BbgS%2Bn4b1VSdajolLvMu%2FIQ5MdUuh0VTxExWw6bL0&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_12' },
      { label: 'Home', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463333031&dc&language=en&ds=v1%3AWWtN7IldxR7v1SJr56N0%2BmumsROmTCbLtUAUWUuyGFc&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_13' },
      { label: 'Industrial & Scientific', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16636960031&dc&language=en&ds=v1%3A%2BUIKPxCG7l3pDKHvKyPVfczGswsRcHRI1g5xK%2Bn0MOA&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_14' },
      { label: 'Musical Instruments', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16637188031&dc&language=en&ds=v1%3AS7HTGq2pyY4bq2Va2SrUp1DpjPYCzJeW%2BvUq1%2BS80eg&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_15' },
      { label: 'Office Products', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16637245031&dc&language=en&ds=v1%3A3MPs0jf6yI3Xe637adaWLLNq2TbgLoURHhnw%2BnvBVeo&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_16' },
      { label: 'Patio, Lawn & Garden', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16636789031&dc&language=en&ds=v1%3AbftBqEgaLkdPPVjFQ%2BjU69I5TZecEjTREkIQyNRHaDc&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_17' },
      { label: 'Pet Supplies', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16637302031&dc&language=en&ds=v1%3A43BNjCVBpYJDwd5U3oZAwQC2StptODiIUTtonhQEcHo&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_18' },
      { label: 'Software', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A16637359031&dc&language=en&ds=v1%3AlPWUWYmy4MGGHAbQx7hmWiFaMNtyHf8NwBN6G06cKYM&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_19' },
      { label: 'Sporting Goods', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463561031&dc&language=en&ds=v1%3AApeNY7sLNDZTe6FLLbuMeKN5yqnahFH7xl7a1nSSR4k&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_20' },
      { label: 'Toys', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463618031&dc&language=en&ds=v1%3Av4U0QdTMBMbtfACBhKhkRAKpUn0PhFweB6os%2FK%2BUmCE&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_21' },
      { label: 'Videogames', value: 'https://www.amazon.sa/s?i=merchant-items&me=A14PAP71RVPZX1&rh=n%3A12463675031&dc&language=en&ds=v1%3Aa37OqP6TE%2FZaOlDYhk1R%2BpoLuQ6X%2FFz8x2STg5qB4QM&marketplaceID=A17E79C6D8DWNP&qid=1697683253&rnid=17120842031&ref=sr_nr_n_22' }
    ]
  },
  {
    label: 'Amazon.ae > Amazon UK',
    options: [
      { label: 'Amazon Devices & Accessories', value: 'https://www.amazon.ae/s?rh=n%3A12794738031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_1&ds=v1%3AnXCwKhlQiNvW9wxIrjhdEn7Vperb2c%2FGWbn6JHDJtdE' },
      { label: 'Appliances', value: 'https://www.amazon.ae/s?rh=n%3A15149780031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_2&ds=v1%3AC6QumtNgidO%2BpzW6gDU9njO2zlVIzlEf6weTWGs9%2Ft4' },
      { label: 'Arts & Crafts', value: 'https://www.amazon.ae/s?rh=n%3A15149837031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_3&ds=v1%3A2IvlAH0%2BZSbX2i46No5NRKjIGcbdn3BxO3C%2FqzKPtsQ' },
      { label: 'Automotive', value: 'https://www.amazon.ae/s?rh=n%3A11498030031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_4&ds=v1%3AwWVSNj2xbeU8SYNNAaocgGZlRK0OmRAKSju1WPA30VE' },
      { label: 'Baby Products', value: 'https://www.amazon.ae/s?rh=n%3A11498087031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_5&ds=v1%3AqLfzobrCrkHPawWj196b1Drto%2B6BRBdxLpGVQ2h60Fs' },
      { label: 'Beauty', value: 'https://www.amazon.ae/s?rh=n%3A11497859031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_6&ds=v1%3Alk%2Bf83yFtncd3i6eh6PV2vAI%2B4xy%2BYD3bBsO5qAq3zY' },
      { label: 'Books', value: 'https://www.amazon.ae/s?rh=n%3A11497688031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_7&ds=v1%3A7bpzolJZwthBNNa3L6KlUU4HyyiHv3jmSDvo8DUX8sg' },
      { label: 'Computers', value: 'https://www.amazon.ae/s?rh=n%3A11497745031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_8&ds=v1%3APtzDT826DVqbjm1ebkCK%2BpM%2Fxz%2F8lIaUabD7KKSsZSc' },
      { label: 'Electronics', value: 'https://www.amazon.ae/s?rh=n%3A11601326031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_9&ds=v1%3AIXNZPAqI0KH0ubV2FowpABI06fGwRxFgbtiQAvS2ckk' },
      { label: 'Fashion', value: 'https://www.amazon.ae/s?rh=n%3A11497631031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_10&ds=v1%3APdxYRlEGZ7%2Bacu%2FkA8THmJC1xPPjnPoFNLtj3zKoOoE' },
      { label: 'Gift Cards', value: 'https://www.amazon.ae/s?rh=n%3A15149951031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_11&ds=v1%3Aw5Czomsaotr0Dc5AmQkNR3iAza4%2FIDqyfS8%2BjJquh4I' },
      { label: 'Grocery', value: 'https://www.amazon.ae/s?rh=n%3A15150008031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_12&ds=v1%3A9s3ODIHw5XTLHOEbyzzLtxVP3IhKElf4vWyq%2B1uctwU' },
      { label: 'Health', value: 'https://www.amazon.ae/s?rh=n%3A11601440031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_13&ds=v1%3AybB8%2FCs%2BjAANq8yKGf9MluAU7DkXZbOqcOsLZvwH6Us' },
      { label: 'Home', value: 'https://www.amazon.ae/s?rh=n%3A11497916031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_14&ds=v1%3AR1hyRTJPKEC2dz2VBnmOIDrt%2FfxMHovcbPF7porlOec' },
      { label: 'Industrial & Scientific', value: 'https://www.amazon.ae/s?rh=n%3A15150065031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_15&ds=v1%3AkaRV2668iGg6llricZ1g7lqKOT1bIedpPqS8GPt7m8U' },
      { label: 'Kitchen', value: 'https://www.amazon.ae/s?rh=n%3A11497973031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_16&ds=v1%3AUEo9nOnVuSyBsE8fcX%2BQZ3Q2r3GRXNEPSeFpzuCZH%2Fc' },
      { label: 'Lighting', value: 'https://www.amazon.ae/s?rh=n%3A12421861031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_17&ds=v1%3AKlbpcHtABJSlzdXFDH3%2BtxVcINCHzLN1VUMv9951bxs' },
      { label: 'Musical Instruments', value: 'https://www.amazon.ae/s?rh=n%3A15150236031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_18&ds=v1%3A3qJ3KR6wq73Lx1WP2oDp1iWfPI47Z4hp1Qkg2qzgKrQ' },
      { label: 'Office Products', value: 'https://www.amazon.ae/s?rh=n%3A15150350031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_19&ds=v1%3A%2FEIWQlw2uzrDIwx61IDljIOgyQsnAAhZwP8Mb4UaLpU' },
      { label: 'Patio, Lawn & Garden', value: 'https://www.amazon.ae/s?rh=n%3A15149894031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_20&ds=v1%3Aq0GRr3Gm%2Fv%2BW0YWiOxUJTUvhTc59lPD8TQ4HAW%2BHIV4' },
      { label: 'Pet Supplies', value: 'https://www.amazon.ae/s?rh=n%3A15150407031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_21&ds=v1%3AYSRZp0JCyLbCU5EdTaTsYTQBVoIqqDr7hp0utjT11l4' },
      { label: 'Services', value: 'https://www.amazon.ae/s?rh=n%3A17525046031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_22&ds=v1%3AYj%2B33lj5D%2Bf%2FwN2cr1YTriZ9cFPZDS%2BdyzC1PUq3fWQ' },
      { label: 'Software', value: 'https://www.amazon.ae/s?rh=n%3A15150464031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_23&ds=v1%3A7Geh5CcANFScxqw7ZG54qIrnJJ%2FPsCjuxxWhNtRZcyk' },
      { label: 'Sporting Goods', value: 'https://www.amazon.ae/s?rh=n%3A11601212031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_24&ds=v1%3A1frG0vmiXYZqSaUcUAZ3vUw1uRVoThXqjPn7FjF3dVc' },
      { label: 'Tools & Home Improvement', value: 'https://www.amazon.ae/s?rh=n%3A11601269031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_25&ds=v1%3Aamy%2BWO2kmZ5o0Ey7L1iiK1Fch5MyVlBQ8ZITYqIEU5Q' },
      { label: 'Toys', value: 'https://www.amazon.ae/s?rh=n%3A11497802031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_26&ds=v1%3A3WYwBBIYYHL7XXGhFaIqTCpxhj2YbGtziKYdkvrqluY' },
      { label: 'Videogames', value: 'https://www.amazon.ae/s?rh=n%3A11601383031%2Cp_6%3AA18S2T518BNNTX&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483645&rnid=15199045031&ref=sr_nr_n_27&ds=v1%3AW0Gns5tTIeYBdUPMMtNa7GNeEuYBKVKtaVlN4aHbeFQ' }
    ]
  },
  {
    label: 'Amazon.ae > Amazon US',
    options: [
      { label: 'Amazon Devices & Accessories', value: 'https://www.amazon.ae/s?rh=n%3A12794738031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_1&ds=v1%3AyiOLPjL0EHO980ocFi5HoI2oS%2FYLriBLZdAvkNuFduI' },
      { label: 'Appliances', value: 'https://www.amazon.ae/s?rh=n%3A15149780031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_2&ds=v1%3AnYrz17GFmkdalLqu4dF8yeLcrgmFFV8c4P0gQpCiTkU' },
      { label: 'Arts & Crafts', value: 'https://www.amazon.ae/s?rh=n%3A15149837031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_3&ds=v1%3Ad2GGj7B1FnaS5B83d2dKJIgQdQ7LjM3LjhlYx8WL%2F7k' },
      { label: 'Automotive', value: 'https://www.amazon.ae/s?rh=n%3A11498030031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_4&ds=v1%3AbK0sy7RXWk0Yvuqjszusxdg5q5%2BnCzNMX4aWdR4dAAI' },
      { label: 'Baby Products', value: 'https://www.amazon.ae/s?rh=n%3A11498087031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_5&ds=v1%3AjCOCDjlRPq7hRwlgLlHLzp%2BeBKWm2GuR0f8%2FFvUyNtc' },
      { label: 'Beauty', value: 'https://www.amazon.ae/s?rh=n%3A11497859031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_6&ds=v1%3AjKpZt3hsqLE8yJcWRbuiWp2Slov%2BL6UpzWvj71dGeJ8' },
      { label: 'Books', value: 'https://www.amazon.ae/s?rh=n%3A11497688031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_7&ds=v1%3A%2Fdr2266VgXvVNnLjNNNixqq%2FXp9Wldp%2BQYr1kygiNgE' },
      { label: 'Computers', value: 'https://www.amazon.ae/s?rh=n%3A11497745031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_8&ds=v1%3AmSZKfGdyH%2BCIlJAjGXKiNq0gDUjYV2eoje%2FcfGao5QE' },
      { label: 'Electronics', value: 'https://www.amazon.ae/s?rh=n%3A11601326031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_9&ds=v1%3ApuNvfh%2F7N%2F2UxGR933STzX4VGHa1f0tt2eq1OApoiYA' },
      { label: 'Fashion', value: 'https://www.amazon.ae/s?rh=n%3A11497631031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_10&ds=v1%3A0W%2Bsv9ONubGGvSfHW5wy2U25yt8ohkapi5tM3skkw7A' },
      { label: 'Gift Cards', value: 'https://www.amazon.ae/s?rh=n%3A15149951031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_11&ds=v1%3AU1h0Qx%2FFQAtXOX1AZU0hx3V02SkBVgKXWx6YCnZs7cQ' },
      { label: 'Grocery', value: 'https://www.amazon.ae/s?rh=n%3A15150008031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_12&ds=v1%3A%2FEZHNQi5U0FKd3bSR9mcfl3vw6y844FBSFmSV267gxA' },
      { label: 'Health', value: 'https://www.amazon.ae/s?rh=n%3A11601440031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_13&ds=v1%3AmDk%2FMz2vdQ7HbSCX6eMtmtMWz2iq751CplM%2FMhDaDxk' },
      { label: 'Home', value: 'https://www.amazon.ae/s?rh=n%3A11497916031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_14&ds=v1%3AyOxqm%2B%2Fec6mI5tNbTUoghky60neVHuftNYMv8NJhfq0' },
      { label: 'Industrial & Scientific', value: 'https://www.amazon.ae/s?rh=n%3A15150065031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_15&ds=v1%3AG92IrqqD6MVmLqX21dRDM1%2FPL33Nl7ftb9HsaNXJo5k' },
      { label: 'Kitchen', value: 'https://www.amazon.ae/s?rh=n%3A11497973031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_16&ds=v1%3A2nNPXnGNXCC5zO7mYL1MD08PXp6OCaXKJUXX%2B4nwcNs' },
      { label: 'Lighting', value: 'https://www.amazon.ae/s?rh=n%3A12421861031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_17&ds=v1%3Av0Nv3BdkBbviN6YefWScRdwzlc8IsSqx9hU6JY2itJk' },
      { label: 'Musical Instruments', value: 'https://www.amazon.ae/s?rh=n%3A15150236031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_18&ds=v1%3AIecMPp84reJEDAW64c79eXanDQVmqTtCfkXKg0qkJrc' },
      { label: 'Office Products', value: 'https://www.amazon.ae/s?rh=n%3A15150350031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_19&ds=v1%3Aq30BT2fZOlZ3EmJk1L%2BvD3I97ClkosHnZfg0IyNK4KY' },
      { label: 'Patio, Lawn & Garden', value: 'https://www.amazon.ae/s?rh=n%3A15149894031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_20&ds=v1%3APAhmlHBFqR5vjgcdSMzoWiNSQHkoem1wyBJWTfsQVfg' },
      { label: 'Pet Supplies', value: 'https://www.amazon.ae/s?rh=n%3A15150407031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_21&ds=v1%3AbWUgC8sl1TCE%2FI8dQywWtEW7%2BdNlX0vzhrQCaVSJCGY' },
      { label: 'Services', value: 'https://www.amazon.ae/s?rh=n%3A17525046031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_22&ds=v1%3Aah7l8CdPV0lD1e%2BRZ3ZXTP%2FdN%2BoyAZy8ighH57IhpIY' },
      { label: 'Software', value: 'https://www.amazon.ae/s?rh=n%3A15150464031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_23&ds=v1%3AHqQRrJLtevohhJupxqPBxDsdJvHoTz%2BNSiVxSVNKqmQ' },
      { label: 'Sporting Goods', value: 'https://www.amazon.ae/s?rh=n%3A11601212031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_24&ds=v1%3AfG%2F5G23UbwrbSoChGrZBlrmdUMb7KO0X3g84QxxaRG4' },
      { label: 'Tools & Home Improvement', value: 'https://www.amazon.ae/s?rh=n%3A11601269031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_25&ds=v1%3AN6HRIM6uDI0gT6NDdnxrrUfPM0oZXxWv2qAiMtbL3YU' },
      { label: 'Toys', value: 'https://www.amazon.ae/s?rh=n%3A11497802031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_26&ds=v1%3A15Npk2JH4wfNr5gE7ElsyU5Xn4zwI2nG2TzOe0fLj1w' },
      { label: 'Videogames', value: 'https://www.amazon.ae/s?rh=n%3A11601383031%2Cp_6%3AA2QUTRSO1ZHRN9&dc&marketplaceID=A2VIGQ35RCS4UG&qid=1696483646&rnid=15199045031&ref=sr_nr_n_27&ds=v1%3AyptnyWWwJ0tlTxmrf6wO3hOVBQEZJVHNXPFA8Ai%2FmaA' }
    ]
  }
];


function App() {
  const [consoleOutput, setConsoleOutput] = useState('');
  const [fileName, setFileName] = useState(null);
  // const [isScraping, setIsScraping] = useState(false);

  const [profitMargin, setProfitMargin] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(() => localStorage.getItem('isLoggedIn') === 'true');
  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'TR');
  const [sessionId, setSessionId] = useState(null);
  const [useProxy, setUseProxy] = useState(false);
  const [name, setName] = useState(null);
  const [isScanning, setIsScanning] = useState(false);
  const [url, setUrl] = useState('');

  const [timeElapsedTwo, setTimeElapsed] = useState('00:00:00');
  const [timer, setTimer] = useState(null);

  const [recentScans, setRecentScans] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    localStorage.setItem('lang', lang);
  }, [lang]);

  const [scrapeStatus, setScrapeStatus] = useState({
    pagesScanned: '0',
    asinsScanned: '0',
    timeElapsed: '00:00:00',
    name: 'Ziyaretçi',
  });

  useEffect(() => {
    if (sessionId !== null && isScanning) {
      document.title = translate('scanning_title');
    } else if (sessionId !== null) {
      document.title = translate('scanning_completed_title');
    } else {
      document.title = "ASINER v3.0 | ASIN Fetcher";
    }
  }, [isScanning, sessionId]);
  
  

  useEffect(() => {
    let intervalId;
  
    if (isScanning) {
      const start = new Date();
  
      intervalId = setInterval(() => {
        const now = new Date();
        const elapsed = new Date(now - start);
        const formattedTime = formatTime(elapsed);
        setTimeElapsed(formattedTime);
      }, 1000);
  
      setTimer(intervalId);
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isScanning]);

  // useEffect(() => {
  //   if (isScanning) {
  //     const start = new Date();
  //     const newTimer = setInterval(() => {
  //       const now = new Date();
  //       const elapsed = new Date(now - start);
  //       const formattedTime = formatTime(elapsed);
  //       setTimeElapsed(formattedTime);
  //     }, 1000);
  //     setTimer(newTimer);
  //   } else {
  //     // clearInterval(timer);
  //     // setTimer(null);
  //     // console.log("Süre durduruldu.");
  //   }
  // }, [isScanning]); // Sadece isScanning değişkeni bağımlılık olarak kullanılıyor
  


  const [connectionStatus, setConnectionStatus] = useState('open'); // 'open', 'in-progress', 'dead'
  const [currentDomain, setCurrentDomain] = useState('amazon.ae'); // Varsayılan olarak amazon.ae

  const extractDomain = (url) => {
    const match = url.match(/https?:\/\/(?:www\.)?(amazon\.(ae|sa|com.au))/);
    return match ? match[1] : 'amazon.ae';  // Eğer eşleşme yoksa varsayılan olarak 'amazon.ae' döndür
  };

  const ws = useRef(null);

  // Domain'i güncelleme ve WebSocket'i başlatma
  useEffect(() => {
    ws.current = new WebSocket('wss://asn-api.masterdrop.co/ws'); // Sunucunuzun adresini gireceksiniz.
    
    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    // API'den son tarama verilerini çekme
    const fetchRecentScans = async () => {
      try {
        const response = await axios.get('https://asn-api.masterdrop.co/recent-scans');
        
        setRecentScans(response.data);
      } catch (error) {
        console.error('Son taramaları çekerken hata oluştu:', error);
      }
    };

    fetchRecentScans();
  }, []);
  
  // WebSocket olaylarını ayarlama
  useEffect(() => {
    if (ws.current) {
      ws.current.onopen = () => {
        // Websocket bağlantısı açıldıktan sonra aktif domain'i gönder
        ws.current.send(JSON.stringify({ type: 'setDomain', domain: currentDomain }));
      };
  
      ws.current.onmessage = (event) => {
        const { type, status } = JSON.parse(event.data);
        if (type === 'status') {
          setConnectionStatus(status);
        }
      };
      
      ws.current.onclose = () => {
        console.log("WebSocket bağlantısı kapandı.");
      };
    }
  }, [currentDomain]);  

  useEffect(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ type: 'setDomain', domain: currentDomain }));
    }
  }, [currentDomain]);

  useEffect(() => {
    const domain = extractDomain(url);
    if (domain) {
      setCurrentDomain(domain);
    }
  }, [url]);

  // const [connectionStatus, setConnectionStatus] = useState('open'); // 'open', 'in-progress', 'dead'
  // const [currentDomain, setCurrentDomain] = useState('amazon.ae'); // Varsayılan olarak amazon.ae

  // // Domain'i URL'den çıkartmak için bir fonksiyon
  // const extractDomain = (url) => {
  //   const match = url.match(/https?:\/\/(?:www\.)?([a-zA-Z0-9.-]+)/);
  //   return match ? match[1] : null;
  // };

  // // Bağlantıyı kontrol etmek için bir fonksiyon
  // const checkConnection = async (domain) => {
  //   const startTime = Date.now();
  //   try {
  //     await axios.get(`https://${domain}`); // Belirtilen domain ile bağlantı kur
  //     const elapsed = Date.now() - startTime;
  //     if (elapsed < 2000) {
  //       setConnectionStatus('open'); // 2 saniyeden az sürdüyse bağlantı "Stabil"
  //     } else {
  //       setConnectionStatus('in-progress'); // 2 saniyeden fazla sürdüyse bağlantı "Stabil değil"
  //     }
  //   } catch (error) {
  //     setConnectionStatus('dead'); // Bağlantı hatası "Kötü"
  //   }
  // };

  // useEffect(() => {
  //   const domain = extractDomain(url); // url, tarama için kullanılan URL'dir
  //   if (domain) {
  //     setCurrentDomain(domain);
  //   }

  //   // Belirli bir aralıkla bağlantıyı kontrol et
  //   const intervalId = setInterval(() => {
  //     checkConnection(currentDomain);
  //   }, 5000); // Her 5 saniyede bir

  //   // Temizleme fonksiyonu
  //   return () => clearInterval(intervalId);
  // }, [url, currentDomain]);


  // const saveScanData = async (url, downloadLink) => {
  //   try {
  //     const response = await fetch('/api/save-scan', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ url, downloadLink })
  //     });
  //     const data = await response.json();
  //     if (data.message) {
  //       console.log(data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error saving scan data:', error);
  //   }
  // };





  // const fetchRecentScans = async () => {
  //   try {
  //     const response = await fetch('/api/recent-scans');
  //     const data = await response.json();
  //     setRecentScans(data);
  //   } catch (error) {
  //     console.error('Error fetching recent scans:', error);
  //   }
  // };
  
  

  const connectWebSocket = (email) => {
    const ws = new WebSocket(`wss://asn-api.masterdrop.co/ws`);
  
    ws.onopen = () => {
      ws.send(JSON.stringify({ email }));
  
      if (sessionId != null) {
        ws.send(JSON.stringify({ sessionId }));
      }
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      let name = response.name;
      if (name === null || name === undefined || name === "") {
        name = 'Ziyaretçi';
      } else {
        setName(name);
      }
    };
  
    // Diğer işlemler
  };
  
  
  useEffect(() => {
    const email = localStorage.getItem('email');

    if (email && isLoggedIn) {
      connectWebSocket(email);
    }
  
    if (!email) {
      setIsLoggedIn(false);
      return; // Erken çıkış, email yoksa WebSocket bağlantısını kurma
    }
  
    const ws = new WebSocket(`wss://asn-api.masterdrop.co/ws`);
  
    ws.onopen = () => {
      ws.send(JSON.stringify({ email }));
  
      if (sessionId != null) {
        ws.send(JSON.stringify({ sessionId }));
      }
    };
  
    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      let name = response.name; // Kullanıcının adı
    
      if (name === null || name === undefined || name === "") {
        name = 'Ziyaretçi'; // Eğer ad tanımlanmamış ise, "Ziyaretçi" olarak ayarla
      } else {
        setName(name);
      }

      if (response.type === 'progress') {
        setScrapeStatus({
          pagesScanned: response.pagesScanned,
          asinsScanned: response.asinsScanned,
          timeElapsed: response.timeElapsed,
          name: name,
        });
      }
    };    
  
    // Component unmount olduğunda bağlantıyı kapatın
    return () => {
      ws.close();
    };
  }, [sessionId, setName, setScrapeStatus, setIsLoggedIn]);
  
  
  
  



  
  const [kategori, setKategori] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [maxPages, setMaxPages] = useState(400);
  const [minPrice, setMinPrice] = useState(25);
  const [maxPrice, setMaxPrice] = useState(500);
  const [includeShippingFee, setIncludeShippingFee] = useState(true); // checkbox'ın başlangıç durumunu ayarlar
  
  const handleCategoryChange = (selectedOption) => {
    setUrl(selectedOption.value);
    setKategori(selectedOption.label);
    setShowInput(false); // Select'ten seçim yapıldığında input'u gizle
  };

  const toggleInputVisibility = () => {
    setShowInput(!showInput);
  };

  useEffect(() => {
    if (isLoggedIn) {
      require('./app-2.css');
    } else {
      require('./app-3.css');
    }
  }, [isLoggedIn]);
  

  const buttonStyle = {
    backgroundColor: isLoading ? '#16214dc7' : '',
    cursor: isLoading ? 'default' : 'pointer',
  };
  const translate = (key) => translations[lang][key] || key;

  const translations = {
    EN: {
      lang_maxpages: 'Max Page',
      lang_minprice: 'Min Price',
      lang_maxprice: 'Max Price',
      lang_profitmargin: 'Profit Margin (%)',
      lang_filter1: 'Do not include products with shipping charges (include Prime)',
      lang_startscraping: 'Scan',
      lang_csvdownload: 'Download CSV',
      lang_login: 'Sign In',
      lang_login_success: 'Login successful. You are being redirected...',
      lang_login_invalid: 'Email address or password is incorrect...',
      lang_login_error: 'An error occurred...',
      lang_login_email: 'E-mail Address',
      lang_login_password: 'Password',
      lang_login_email_desc: 'Enter your email address',
      lang_login_password_desc: 'Enter your password',
      lang_login_3: 'Login',
      lang_scanning: 'Scanning...',
      lang_scan_processing: 'Scan in progress. Please do not close the page...',
      lang_scan_completed: 'Scan completed. File name',
      lang_scan_completed_2: 'Scan completed. Click Download CSV to download.',
      lang_scan_error: 'An error occurred...',
      lang_url_required: 'Please enter a URL or select a category.',
      lang_minprice_required: 'Please fill in the minimum price field.',
      lang_maxprice_required: 'Please fill in the maxmimum price field.',
      lang_maxpages_required: 'Please fill in the maximum page field.',
      lang_invalid_price_range: 'The minimum price cannot be more than the maximum price.',
      lang_invalid_price_range2: 'The maximum price cannot be less than the minimum price.',
      scanned_page: 'Page Scanned',
      scanned_asin: 'ASIN Scanned',
      elapsed_time: 'Elapsed Time',
      proxy_desc: 'Scan with a proxy (a bit slow, recommended for multiple scans)',
      success_login: 'Success!',
      success_login_desc: 'Login is successful. You are redirected...',
      error_invalid_login: 'Error!',
      error_invalid_login_desc: 'The username or password is incorrect.',
      ok_login: 'OK',
      error_login: 'An unexpected error occurred!',
      retry_login: 'Retry',
      welcome: 'Welcome',
      logout: 'Log Out',
      manuelurl: 'Enter the URL manually',
      lang_select_placeholder: 'Select category...',
      lang_no_options: 'Category not found.',
      lang_stopscraping: 'Stop Scanning',
      invalid_url: 'Invalid URL.',
      connection_status: 'Connection Status',
      connection_stable: 'Stable',
      connection_nonstable: 'Not stable',
      connection_bad: 'Bad',
      scanning_title: 'Scanning in Progress 🔄',
      scanning_completed_title: 'Scan Completed ✅'
    },
    TR: {
      lang_maxpages: 'Maksimum Sayfa Sayısı',
      lang_minprice: 'Minimum Fiyat',
      lang_maxprice: 'Maksimum Fiyat',
      lang_profitmargin: 'Kâr Marjı (%)',
      lang_filter1: 'Kargo ücreti olan ürünleri dahil etme (Prime dahil et)',
      lang_startscraping: 'Taramayı Başlat',
      lang_csvdownload: 'CSV\'yi İndir',
      lang_login: 'Oturum Aç',
      lang_login_success: 'Giriş başarılı. Yönlendiriliyorsunuz...',
      lang_login_invalid: 'E-posta adresi veya şifre hatalı...',
      lang_login_error: 'Bir hata oluştu...',
      lang_login_email: 'E-posta Adresiniz',
      lang_login_password: 'Şifreniz',
      lang_login_email_desc: 'E-posta adresinizi girin',
      lang_login_password_desc: 'Şifrenizi girin',
      lang_login_3: 'Giriş',
      lang_scanning: 'Tarama Başladı...',
      lang_scan_processing: 'Tarama sürüyor. Lütfen sayfayı kapatmayınız...',
      lang_scan_completed: 'Tarama tamamlandı. Dosya adı',
      lang_scan_completed_2: 'Tarama tamamlandı. İndirmek için CSV\'yi İndir\'e tıklayınız.',
      lang_scan_error: 'Bir hata oluştu...',
      lang_url_required: 'Lütfen bir URL girin veya bir kategori seçimi yapın.',
      lang_minprice_required: 'Lütfen minimum fiyat girin.',
      lang_maxprice_required: 'Lütfen maksimum fiyat girin.',
      lang_maxpages_required: 'Lütfen maksimum sayfa girin.',
      lang_invalid_price_range: 'Minimum fiyat, maksimum fiyattan fazla olamaz.',
      lang_invalid_price_range2: 'Maksimum fiyat, minimum fiyattan az olamaz.',
      scanned_page: 'Taranan Sayfa',
      scanned_asin: 'Taranan ASIN',
      elapsed_time: 'Geçen Süre',
      proxy_desc: 'Proxy ile tarama yap (biraz yavaş, çoklu taramalarda önerilir)',
      success_login: 'Başarılı!',
      success_login_desc: 'Giriş başarılı. Yönlendiriliyorsunuz...',
      error_invalid_login: 'Hata!',
      error_invalid_login_desc: 'Kullanıcı adı veya şifre yanlış.',
      ok_login: 'Tamam',
      error_login: 'Beklenmeyen bir hata oluştu!',
      retry_login: 'Tekrar Dene',
      welcome: 'Hoş geldin',
      logout: 'Çıkış Yap',
      manuelurl: 'URL\'yi manuel olarak girin',
      lang_select_placeholder: 'Kategori seçin...',
      lang_no_options: 'Kategori bulunamadı.',
      lang_stopscraping: 'Taramayı Durdur',
      invalid_url: 'Geçersiz URL girdiniz.',
      connection_status: 'Bağlantı Durumu',
      connection_stable: 'Stabil',
      connection_nonstable: 'Stabil değil',
      connection_bad: 'Kötü',
      scanning_title: 'Tarama Devam Ediyor 🔄',
      scanning_completed_title: 'Tarama Tamamlandı ✅'
    },
  };

  if (!isLoggedIn) {
    return (
      <div className="container">
        <HelmetProvider>
          <Helmet>
            <title>{isLoggedIn ? "ASINER v3.0 | ASIN Fetcher" : translate('lang_login') + ' | ASINER v3.0'}</title> 
            <link rel="icon" href="https://gcdnb.pbrd.co/images/sV1D98WxMQSZ.png" />
          </Helmet>
        </HelmetProvider>
        <div className="language-switch">
          <button className="lang-switch-btn" onClick={() => setLang('TR')}>TR</button>
          <button className="lang-switch-btn" onClick={() => setLang('EN')}>EN</button>
        </div>
        <span id="warn"></span>
        <header style={{ justifyContent: 'center' }}>
          <img src="https://i.ibb.co/7tFMYpH/asiner-logo.png" alt="Logo" />
        </header>
        <h2 className="headerItem">{translate('lang_login')}</h2>
        <form onSubmit={(e) => {
            e.preventDefault();
            setIsLoading(true);
            const username = e.target.username.value;
            const password = e.target.password.value;

            axios.post('https://asn-api.masterdrop.co/login', { username, password })
            .then(response => {
                if (response.data.success) {
                    Swal.fire({
                        title: translate('success_login'),
                        text: translate('success_login_desc'),
                        icon: 'success',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        timer: 3000,
                        timerProgressBar: true
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                          setIsLoading(false);
                          setIsLoggedIn(true);
                          connectWebSocket(username); // email olarak username kullanılıyor
                          localStorage.setItem('isLoggedIn', 'true');
                          localStorage.setItem('email', username);
                          localStorage.setItem('name', username); // Eğer name bilgisi gerekiyorsa
                        }
                    });
                } else {
                    setIsLoggedIn(false);
                    localStorage.removeItem('isLoggedIn');
                    Swal.fire({
                        title: translate('error_invalid_login'),
                        text: translate('error_invalid_login_desc'),
                        icon: 'error',
                        confirmButtonText: translate('ok_login'),
                        confirmButtonColor: '#16255D',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setIsLoading(false);
                        }
                    });
                }
            })
            .catch(error => {
                console.error(error);
                setIsLoggedIn(false);
                localStorage.removeItem('isLoggedIn');
                Swal.fire({
                    title: translate('error_invalid_login'),
                    text: translate('error_login'),
                    icon: 'error',
                    confirmButtonText: translate('retry_login'),
                }).then((result) => {
                  if (result.isConfirmed) {
                      setIsLoading(false);
                  }
              });
            });

        }}>
          <label>
            <span className="input-label">{translate('lang_login_email')}</span>
            <div className="input-group">
              <input type="text" placeholder={translate('lang_login_email_desc')} name="username" required />
              <FontAwesomeIcon icon={faEnvelope} className="input-icon" />
            </div>
          </label>
          <label>
            <span className="input-label">{translate('lang_login_password')}</span>
            <div className="input-group">
              <input type="password" placeholder={translate('lang_login_password_desc')} name="password" required />
              <FontAwesomeIcon icon={faLock} className="input-icon" />
            </div>
          </label>
          <button id="loginButton" type="submit" disabled={isLoading} style={buttonStyle}>
            {isLoading ? 
              <div className="loading-spinner-container">
                <div className="loading-spinner"></div>
              </div> 
              : translate('lang_login_3')}
          </button>
          {/* <p style={{ fontSize: '15px', display: 'flex' }}>Yeni misin? <a href='#' style={{ fontSize: '15px', textDecoration: 'underline', marginTop: '0', marginLeft: '5px' }}>Hesap oluştur!</a></p> */}
        </form>
      </div>
    );
  }

  const startScraping = async (e) => {
    e.preventDefault();

    if (!url) {
      setConsoleOutput(translate('lang_url_required'));
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_url_required') + "</span>";
      setIsScanning(false);
      return;  // URL boşsa, daha fazla işlem yapmayı durdur
    } 
    if (!url.includes('amazon.sa') && !url.includes('amazon.ae') && !url.includes('amazon.com.au')) {
      setConsoleOutput(translate('invalid_url'));
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('invalid_url') + "</span>";
      setIsScanning(false);
      return; 
    }    
    if (minPrice === "") {
      setConsoleOutput(translate('lang_minprice_required'));
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_minprice_required') + "</span>";
      return;
    }
    if (maxPrice === "") {
      setConsoleOutput(translate('lang_maxprice_required'));
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_maxprice_required') + "</span>";
      return;
    }
    if (maxPages === "") {
      setConsoleOutput(translate('lang_maxpages_required'));
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_maxpages_required') + "</span>";
      return;
    }
    if (Number(minPrice) > Number(maxPrice)) {
      setConsoleOutput(translate('lang_invalid_price_range'));
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_invalid_price_range') + "</span>";
      return;
    }
    if (Number(maxPrice) < Number(minPrice)) {
      setConsoleOutput(translate('lang_invalid_price_range2'));
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_invalid_price_range2') + "</span>";
      return;
    }

    setIsScanning(true);

    const currentSessionId = Date.now(); // Bu değeri bir değişkende saklayın.
    setSessionId(currentSessionId); // State'i güncelleyin.

    setConsoleOutput(translate('lang_scanning'));
    document.getElementById("anounce").innerHTML = "<span class='warning'>" + translate('lang_scan_processing') + "</span>";
    
    let finalUrl = url;
    if (url.includes('.sa')) {
      console.log("URL .sa içeriyor, shipping fee görmezden geliniyor.");
    } else if (includeShippingFee) {
      finalUrl += "&ref=sr_nr_p_n_prime_domestic_2&rh=%2Cp_n_prime_domestic%3A20642116031";
    } else {
      console.log("Shipping fee dahil edilmiyor.");
    }

    try {
      const res = await axios.post('https://asn-api.masterdrop.co/scrape', {
        url: finalUrl, maxPages, minPrice, maxPrice, profitMargin, sessionId: currentSessionId, useProxy, kategori
      });
      document.getElementById("anounce").innerHTML = "<span class='success'>" + translate('lang_scan_completed_2') + "</span>";
      setFileName(res.data.fileName);
    } catch (error) {
      setConsoleOutput(prevOutput => `${prevOutput}\nHata: ${error.message}`);
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_scan_error') + "</span>";
    } finally {
      setIsScanning(false);
    }
  };

  const stopScraping = async () => {
    setIsScanning(false);
    // try {
    //   await axios.post('https://asn-api.masterdrop.co/stop-scrape', { sessionId });
    //   console.log("Scraping stopped for session:", sessionId);
    // } catch (error) {
    //   console.error("Could not stop scraping:", error);
    // }


    try {
      const res = await axios.post('https://asn-api.masterdrop.co/stop-scrape', { sessionId });
      if (res.status === 200) {
        console.log("Successfully stopped scraping");
        document.getElementById("anounce").innerHTML = "<span class='success'>" + translate('lang_scan_completed_2') + "</span>";
      }
    } catch (error) {
      console.error("Could not stop scraping:", error);
      document.getElementById("anounce").innerHTML = "<span class='error'>" + translate('lang_scan_error') + "</span>";
    }
  };

  const formatTime = (time) => {
    const hours = time.getUTCHours().toString().padStart(2, '0');
    const minutes = time.getUTCMinutes().toString().padStart(2, '0');
    const seconds = time.getUTCSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };


  // const parseConsoleOutput = (output) => {
  //   if (!output) return { pageNumber: '0', asinCount: '0', elapsedTime: '00:00:00' };
  
  //   const parts = output.split('|').map(part => part.trim());
  //   if (parts.length < 3) return { pageNumber: '0', asinCount: '0', elapsedTime: '00:00:00' };
  
  //   let pageNumber = parts[0].split(':')[1]?.trim() || '0';
  //   let asinCount = parts[1].split(':')[1]?.trim() || '0';
    
  //   // elapsedTime'ı düzeltme
  //   let elapsedTime = parts[2].split(':').slice(1).join(':')?.trim() || '00:00:00';
  
  //   return { pageNumber, asinCount, elapsedTime };
  // }
  
  

  const ConsoleOutput = () => {
    const { pagesScanned, asinsScanned, timeElapsed } = scrapeStatus;

    return (
      <div className="console-output">
        <div className="console-item">
          <h2>{translate('scanned_page')}</h2>
          <p>{pagesScanned}</p>
        </div>
        <div className="console-item">
          <h2>{translate('scanned_asin')}</h2>
          <p>{asinsScanned}</p>
        </div>
        <div className="console-item">
          <h2>{translate('elapsed_time')}</h2>
          <p>{timeElapsedTwo}</p>
        </div>
      </div>
    );
}

  

  // const stopScraping = async () => {
  //   if (!isScraping) {
  //     return;
  //   }

  //   try {
  //     await axios.post('http://asn-api.masterdrop.co/stop-scrape');
  //     setConsoleOutput(prevOutput => `${prevOutput}\nTarama durduruldu.`);
  //   } catch (error) {
  //     setConsoleOutput(prevOutput => `${prevOutput}\nHata: ${error.message}`);
  //   } finally {
  //     setIsScraping(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchRecentScans();
  // }, []);

  const RecentScans = () => {
  
    return (
      <div>
        {showModal && (
          <div className="modal">
            <button onClick={() => setShowModal(false)}>Kapat</button>
            <ul>
              {recentScans.map(scan => (
                <li key={scan.id}>
                  URL: {scan.url}
                  <br />
                  İndirme Linki: <a href={scan.download_link}>İndir</a>
                  <br />
                  Tarama Tarihi: {new Date(scan.scan_date).toLocaleString()}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  function shortenUrl(url, maxLength = 30) {
    return url.length > maxLength ? url.substring(0, maxLength) + '...' : url;
  }  

  return (
    <div className="container">
      <div className="language-switch">
        <button className="lang-switch-btn" onClick={() => setLang('TR')}>TR</button>
        <button className="lang-switch-btn" onClick={() => setLang('EN')}>EN</button>
      </div>
      <div className="statusamazon">
        <li className={`status ${connectionStatus}`}>
          {translate('connection_status')}: {connectionStatus === 'open' ? translate('connection_stable') + ' (' + currentDomain + ')' : connectionStatus === 'in-progress' ? translate('connection_nonstable') + ' (' + currentDomain + ')' : translate('connection_bad') + ' (' + currentDomain + ')'}
        </li>
      </div>
      <div className="logomobilediv">
        <img className="logomobile" src="https://i.ibb.co/7tFMYpH/asiner-logo.png" alt="Logo" />
      </div>
      <span id="anounce"></span>
      <HelmetProvider>
        <Helmet>
          <title>{isLoggedIn ? "ASINER v3.0 | ASIN Fetcher" : translate('lang_login')}</title> 
          <link rel="icon" href="https://gcdnb.pbrd.co/images/sV1D98WxMQSZ.png" />
        </Helmet>
      </HelmetProvider>
      <header>
        <div>
        
        <button style={{marginBottom:'17px'}} onClick={() => setShowModal(true)}>Son Tarananlar</button>

        {showModal && (
          <div className="modal-backdrop">
            <div className="modal">
              <button className="close-button" onClick={() => setShowModal(false)}>X</button>
              <table>
                <thead>
                  <tr>
                    <th style={{width:'35%'}}>URL</th>
                    <th style={{width:'19%'}}>Kategori Adı</th>
                    <th style={{width:'20%'}}>İndirme Linki</th>
                    <th style={{width:'18%'}}>Tarama Tarihi</th>
                  </tr>
                </thead>
                <tbody>
                  {recentScans.map(scan => (
                    <tr key={scan.id}>
                      <td>
                        <a href={scan.url} target="_blank" rel="noopener noreferrer">
                          {shortenUrl(scan.url)}
                        </a>
                      </td>
                      <td style={{textTransform:'capitalize'}}>{scan.sanitizedKategori}</td>
                      <td><a href={scan.download_link}>İndir</a></td>
                      <td>{new Date(scan.scan_date).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

          <h1>ASIN Fetcher</h1>
          <div className="containerj">
            <p>{translate('welcome')} {name}!</p>
            <button
              className="logout-button"
              onClick={() => {
                setIsLoggedIn(false);
                localStorage.removeItem('isLoggedIn');
                localStorage.removeItem('email');
              }}
            >
              {translate('logout')}
            </button>
          </div>
        </div>
        <img className="logoweb" src="https://i.ibb.co/7tFMYpH/asiner-logo.png" alt="Logo" />
      </header>
      <form onSubmit={startScraping}>
        { showInput && (
          <label>
            URL:
            <input type="text" value={url} onChange={e => setUrl(e.target.value)} required />
          </label>
        )}
        <Select style={{ Padding: '2px 8px', height: '40px', alignContent: 'center' }} options={categories} onChange={handleCategoryChange} placeholder={translations[lang].lang_select_placeholder} noOptionsMessage={() => translations[lang].lang_no_options} />
        { !showInput && (
          <button type="button" href="#" style={{ marginLeft: '0px', marginTop: '-12px', fontSize: '13px', textDecoration: 'underline', color: '#0099cf', fontWeight: '500', fontFamily: "'Cerebri Sans', sans-serif", background: 'none', width: 'fit-content', height: 'fit-content', padding: '0px' }} onClick={toggleInputVisibility}>{ translate('manuelurl') }</button>
        )}
        <label>
        {translate('lang_maxpages')}:
          <input type="number" value={maxPages} onChange={(e) => setMaxPages(e.target.value)} required />
        </label>
        <label>
        {translate('lang_minprice')}:
          <input type="number" value={minPrice} onChange={(e) => setMinPrice(e.target.value)} required />
        </label>
        <label>
        {translate('lang_maxprice')}:
          <input type="number" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} required />
        </label>
        <label>
        {translate('lang_profitmargin')}:
          <input 
            type="number" 
            value={profitMargin || ''} 
            onChange={(e) => setProfitMargin(e.target.value ? parseFloat(e.target.value) : null)} 
            min="0" 
            step="1.0" 
          />
        </label>
        <label className="checkbox-container">
          {translate('lang_filter1')}
          <input type="checkbox" checked={includeShippingFee} onChange={() => setIncludeShippingFee(!includeShippingFee)} />
          <span className="custom-checkbox"></span>
        </label>
        <label className="checkbox-container" style={{ opacity: 0.8, cursor: "not-allowed" }} disabled>
        {translate('proxy_desc')}
            <input 
              type="checkbox" 
              checked={useProxy} 
              disabled
              onChange={() => {
                setUseProxy(!useProxy);
                // console.log('useProxy:', !useProxy);  // Bu satırı ekleyin
              }} 
            />
            <span className="custom-checkbox"></span>
        </label>
        {/* <button type="submit" onClick={startScraping}>{translate('lang_startscraping')}</button> */}
        {!isScanning && <button type="submit" onClick={startScraping}>{translate('lang_startscraping')}</button>}
        {isScanning && <button type="submit" style={{ backgroundColor: '#750808' }} onClick={stopScraping}>{translate('lang_stopscraping')}</button>}
        {/* <button type="button" disabled={!isScraping} onClick={stopScraping}>Taramayı Durdur</button>  */}
      </form>
      <ConsoleOutput output={consoleOutput} />
      {/* <pre className="console">{consoleOutput}</pre> */}
      {fileName && <div class="dwn-cs"><a class="dwn-btn" href={`https://asn-api.masterdrop.co/${fileName}`}>{translate('lang_csvdownload')}</a></div>}
      <footer>
        Made with <span role="img" aria-label="love">❤️</span> by <span className="footer-name">ASINER v3.0</span>
      </footer>
    </div>
  );
}

export default App;
